/*.main{*/
/*    height: 812px;*/
/*    width: 375px;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: center;*/
/*    outline: 1px solid black;*/
/*}*/

.main__form{
    background: #FFFFFF;
    border-radius: 32px;
    filter: drop-shadow(0px 8px 16px rgba(17, 17, 17, 0.04));
    height: 384px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0 40px;
}

.main__header{
    font-weight: 700;
    font-size: 32px;
    line-height: 34px;
    letter-spacing: 1px;
    color: #14142B;
}

.main__inputBlock{
    position: relative;
}

.main__inputPlaceholder{
    position: absolute;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: #6E7191;
    left: 60px;
}

.main__inputPhone{
    padding: 20px 15px 0 60px;
    height: 31px;
    width: 174px;
    border-radius: 16px;
    background: #EFF0F7;
    border: none;
    outline: none;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.75px;
    color: #14142B;
}

.main__inputPassword{
    padding: 20px 40px 0 60px;
    height: 31px;
    width: 147px;
    border-radius: 16px;
    background: #FCFCFC;
    border: 2px solid #5F2EEA;
    outline: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.75px;
    color: #14142B;
}

.main__inputPasswordVisible{
    position: absolute;
    top: 16px;
    left: 18px;
}

.main__inputPasswordXMark{
    position: absolute;
    top: 16px;
    right: 54px;
}

.main__inputPasswordVisible:hover, .main__inputPasswordXMark:hover{
    cursor: pointer;
}

.main__button{
    background: #5F2EEA;
    border-radius: 16px;
    height: 51px;
    width: 242px;
    border: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.75px;
    color: #F7F7FC;
}

.main__inputCheckBox{
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.main__inputCheckBox+label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    font-weight: 400;
    font-size: 13px;
    line-height: 28px;
    letter-spacing: 0.75px;
    color: #4E4B66;
}
.main__inputCheckBox+label::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #adb5bd;
    border-radius: 50%;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}

.main__inputCheckBox:checked+label::before {
    border-color: #0b76ef;
    background-color: #0b76ef;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.main__inputCheckBox:not(:disabled):not(:checked)+label:hover::before {
    border-color: #b3d7ff;
}
/* стили для активного состояния чекбокса (при нажатии на него) */
.main__inputCheckBox:not(:disabled):active+label::before {
    background-color: #b3d7ff;
    border-color: #b3d7ff;
}
/* стили для чекбокса, находящегося в фокусе */
.main__inputCheckBox:focus+label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
/* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
.main__inputCheckBox:focus:not(:checked)+label::before {
    border-color: #80bdff;
}
/* стили для чекбокса, находящегося в состоянии disabled */
.main__inputCheckBox:disabled+label::before {
    background-color: #e9ecef;
}

.main__button:hover{
    cursor: pointer;
}