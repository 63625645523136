
* {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    cursor: default;
}

main {
    height: 812px;
    /*width: 375px;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#root{
    width: 100vw;
    display: flex;
    justify-content: center;
}

html {
    position: fixed;
    height: 100%;
    overflow: hidden;
}

body {
    margin: 0;
    width: 100vw;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}