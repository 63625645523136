.chartMain{
    padding-left: 84px;
    height: 100vh;
    justify-content: space-evenly;
    align-items: center;
}

.main__navigationBlock>a{
    /*margin-left: 24px;*/
    font-weight: 400;
    font-size: 12px;
    line-height: 28px;
    letter-spacing: 0.75px;
    color: #5F2EEA;
    text-decoration: none;
    border-bottom: 1px dashed #5F2EEA;
    cursor: pointer;
}

.main__tableOfEmployees{

}

.main__tableOfEmployeesTitle{
    font-weight: 800;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.75px;
    color: #000000;
    display: block;
    margin-bottom: 15px;
}

.main__tableOfEmployeesList{
    height: 346px;
}

.main__tableOfEmployeesList>div{
    margin-bottom: 10px;
}

.main__tableOfEmployeesListSubTitle{
    font-weight: 400;
    font-size: 12px;
    line-height: 28px;
    letter-spacing: 0.75px;
    color: #2F2D3E;
    display: block;
}

.main__tableOfEmployeesListName{
    margin-left: 30px;
    font-weight: 400;
    font-size: 12px;
    line-height: 28px;
    letter-spacing: 0.75px;
    color: #4E4B66;
    display: block;
}

.main__monthlyWorkTable{
    width: 100vw;
    margin-left: -84px;
    padding-bottom: 5px;
}

.main__monthlyWorkTable>div:nth-child(2){
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main__monthlyWorkTableWeekDays{
}

.main__monthlyWorkTableWeekDays{
    display: flex;
    margin-left: 65px;
}

.main__monthlyWorkTableWeekDays>div{
    width: 16px;
    margin-left: 27px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.75px;
}

.main__monthlyWorkTableWeekDays>div:first-child{
    margin-left: 11px;
}

.main__monthlyWorkTableRowEmployees{
    display: flex;
    margin-top: 10px;
}

.main__monthlyWorkTableRowEmployees>div:first-child{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60px;
    height: 35px;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    text-align: center;
    letter-spacing: 0.75px;
    color: #2F2D3E;
}

.main__monthlyWorkTableRowEmployees>div:last-child{
    display: flex;
}

.main__monthlyWorkTableRowEmployees>div:last-child>div{
    content: "";
    background: #EFF0F6;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 7.5px;
}

.main__calendarBlockDaysCarouselDay{
    display: flex;
    flex-direction: column;
    height: 65px;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    margin: 0 5px;
}

.main__calendarBlockDaysCarouselDay>span{
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.75px;
    color: #000000;
}

.main__calendarBlockDaysCarouselDay>div{
    content: "";
    background: #EFF0F6;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}