.adminMain{
    height: 100vh;
    justify-content: flex-start;
    padding-top: 25px;
}

.main__navigationBlock>a{
    font-weight: 400;
    font-size: 12px;
    line-height: 28px;
    letter-spacing: 0.75px;
    color: #5F2EEA;
    text-decoration: none;
    border-bottom: 1px dashed #5F2EEA;
    cursor: pointer;
    text-decoration: none;
    margin-left: 20px;
}

.main__tableTitle{
    font-weight: 800;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    letter-spacing: 0.75px;
    color: #000000;
    margin: 20px 0;
}

.main__tableBlock{
    height: 500px;
}

.main__tableBlock thead{
    background: #D9D9D9;
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-weight: 600;
    font-size: 12px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.75px;
    color: #000000;
}

.main__tableBlock tbody{
    background: #EFF0F6;
    border-radius: 10px;
}

.main__tableBlock th {
    padding: 10px 10px;
}

.main__tableBlock tr>td {
    cursor: pointer;
}

.main__tableBlock td{
    font-weight: 400;
    font-size: 10px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.75px;
    color: #4E4B66;
    padding: 10px 0;
}


.main__button{
    margin-top: 5px;
    outline: none;
    border-radius: 10px;
    padding: 5px 0;
}

.main__modal{
    width: 100vw;
    height: 100vh;
    position: absolute;
    transition: .5s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0, .8);
    left: 0;
}

.main__modal>*{
    margin-bottom: 10px;
}

.main__modal>input, .main__modal>button{
    outline: none;
    border-radius: 10px;
    padding: 10px 15px;
}
