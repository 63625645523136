main{
    justify-content: space-around;
}

.main__headerBlock{
    display: flex;
    border-radius: 32px;
    justify-content: space-around;
    align-items: center;
    height: 115px;
}

.main__headerBlockAvatar{
    display: flex;
    justify-content: center;
    align-items: center;
}

.main__headerBlockAvatar>div{
    content: "";
    width: 50px;
    height: 50px;
    background: #0b76ef;
    border-radius: 50%;
}

.main__headerBlockInfo{
    display: flex;
    flex-direction: column;
}

.main__headerBlockInfo>span{
    font-weight: 700;
    font-size: 20px;
    line-height: 34px;
    letter-spacing: 1px;
    color: #14142B;
}

.main__headerBlockInfo a{
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: #5F2EEA;
    border-bottom: 1px dashed #5F2EEA;
    width: 42px;
    cursor: pointer;
    text-decoration: none;
    margin-right: 20px;
}

.main__incomeBlock{
    background: #5F2EEA;
    border-radius: 32px;
    margin: 0 20px;
    display: flex;
    height: 90px;
    justify-content: space-evenly;
    opacity: 0;
}

.main__incomeBlockInfo{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.main__incomeBlockInfo>span:first-child{
    font-weight: 600;
    font-size: 24px;
    line-height: 38px;
    letter-spacing: 0.75px;
    color: #FFFFFF;
}

.main__incomeBlockInfo>span:first-child>span{
    font-weight: 600;
    font-size: 16px;
    line-height: 34px;
    letter-spacing: 0.75px;
    color: #00BA88;
}

.main__incomeBlockInfo>span:last-child{
    font-weight: 400;
    font-size: 15px;
    line-height: 34px;
    letter-spacing: 0.75px;
    color: #E8E8E8;
}

.main__incomeBlockButtons{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main__incomeBlockButtons>button{
    cursor: pointer;
    padding: 5px 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.75px;
    background: #5F2EEA;
    color: #E0E0E0;
    border: 2px solid #E0E0E0;
    border-radius: 15px;
}

.main__incomeBlockButtons>a{
    cursor: pointer;
    font-weight: 400;
    font-size: 13px;
    line-height: 34px;
    letter-spacing: 0.75px;
    color: #E8E8E8;
    border-bottom: 1px dashed #FFFFFF;
    width: 77px;
}

.main__workShiftBlock{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 25px;
}

.main__workShiftBlock>span:first-child{
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #6E7191;
}

.main__workShiftBlock>span:nth-child(2){
    font-weight: 800;
    font-size: 30px;
    line-height: 34px;
    display: flex;
    align-items: center;
    letter-spacing: 0.75px;
    color: #000000;
}

.main__workShiftBlock>a{
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.75px;
    color: #5F2EEA;
    width: 140px;
    border-bottom: 1px dashed #5F2EEA;
    text-decoration: none;
    cursor: pointer;
}

.main__birthdayBlock{
    margin: 0 20px;
    background: #5F2EEA;
    border-radius: 32px;
    height: 83px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.main__birthdayBlockInfo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #FFFFFF;
}

.main__birthdayBlockInfo>span:first-child{
    font-weight: 400;
    font-size: 13px;
    line-height: 32px;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.main__birthdayBlockInfo>span:last-child{
    font-weight: 800;
    font-size: 14px;
    line-height: 32px;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.main__birthdayBlockDate{
    background: #6E7191;
    box-shadow: -4px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.75px;
    color: #FFFFFF;
    padding: 0 10px;
    height: 49px;
}

.main__employeeStatusBlock{
    display: flex;
    align-items: center;
    height: 83px;
    margin: 0 20px;
    background: #ED2E7E;
    border-radius: 32px;
    justify-content: space-evenly;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 1px;
    color: #F7F7FC;
}

.main__employeeStatusBlock
,.main__employeeStatusBlock *{
    cursor: pointer;

}

.main__calendarBlock{
    display: flex;
    flex-direction: column;
}

.main__calendarBlockMonth{
    display: flex;
    padding: 0 40px;
    justify-content: space-between;
}

.main__calendarBlockMonth>span{
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: #6E7191;
    text-transform: uppercase;
}

.main__calendarBlockMonth>svg{
    color: #6E7191;
    cursor: pointer;
}

.main__calendarBlockDays{
    /*position: relative;*/
    margin: 20px 0 60px 0;
    width: 375px;
    padding: 20px 0;
}

.main__calendarBlockDaysCarousel{
    /*position: absolute;*/
    display: flex;
    transition: .5s;
}

.main__calendarBlockDaysCarouselDay{
    display: flex;
    flex-direction: column;
    height: 65px;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    margin: 0 5px;
}

.main__calendarBlockDaysCarouselDay>span{
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.75px;
    color: #000000;
}

.main__calendarBlockDaysCarouselDay>div{
    content: "";
    background: #EFF0F6;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main__calendarBlockDaysCarouselDay>div, .main__calendarBlockDaysCarouselDay>div * {
    cursor: pointer;
}